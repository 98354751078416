import Bookmarklet from '../links/bookmarklet';
import TelegramLink from '../links/telegram';
import VKontakteLink from '../links/vkontakte';
import EmailLink from '../links/email';

function Footer() {
    return (
        <div className="container-fluid bg-dark text-light footer mt-0 pt-4 pb-1 wow fadeIn" data-wow-delay="0.1s" style={{ marginTop: "6rem" }}>
          <div className="container mb-4">
            <div className="row">
              <div className="col-12">
                <a id="contacts"></a>
                <h5 className="text-white mb-2">Будь на связи</h5>
                <div className="row">
                  <div className="col-6 col-md-4 col-xl-3 mb-3">
                    <i className="fa fa-telegram me-3"></i><TelegramLink />
                  </div>
                  <div className="col-6 col-md-4 col-xl-3 mb-3">
                    <i className="fa fa-vk me-3"></i><VKontakteLink />
                  </div>
                  <div className="col-6 col-md-4 col-xl-3 mb-3 d-none d-sm-inline">
                    <i className="fa fa-tag me-3"></i><Bookmarklet />
                  </div>
                  <div className="col-6 col-md-4 col-xl-3 mb-3">
                    <i className="fa fa-envelope me-3"></i><EmailLink />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container d-none d-sm-inline">
            <div className="copyright">
              <div className="row">
                <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                  Ссылки от <a className="border-bottom" href="https://mrfx.ru">MrFx</a> &copy; {new Date().getFullYear()}
                </div>
                <div className="col-md-6 text-center text-md-end ">
                  <div className="footer-menu">
                    <a href="#about">О сервисе</a>
                    <a href="#prices" className="d-none d-sm-inline">Наши услуги</a>
                    <a href="#service" className="d-none d-sm-inline">Создать ссылку</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

    )
}

export default Footer;