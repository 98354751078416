import Navbar from '../components/navbar';
import Hero from './hero';

function Header({ onShowServiceForm }) {
    return (
        <div className="container-xxl position-relative p-0">
            <Navbar onShowServiceForm={onShowServiceForm} />
            <Hero onShowServiceForm={onShowServiceForm} />
        </div>
    )
}

export default Header;