import React, { useState, useEffect } from "react";
import { useSearchParams } from 'react-router-dom';

import { YMInitializer } from 'react-yandex-metrika';

import Spinner from '../components/spinner';
import Header from '../blocks/header';
import About from '../blocks/about';
import Service from '../blocks/service';
import Footer from '../blocks/footer';
import ApiSpecification from '../blocks/api';
import ServiceForm from '../blocks/service-form';
import Modal from '../ui/modal';

import Storage from "../service/storage-service";
import { setLocation } from "../service/utils";

function MainPage() {

    const [ showSpinner, setShowSpinner ] = useState(true);
    const [ showServiceForm, setShowServiceForm ] = useState(false);
    const [ searchParams ] = useSearchParams();
    let params = {};
    for (let [key, value] of searchParams.entries()) { params[key] = value; };

    if ( params['referral'] ?? null ) {
        Storage.setReferral(params['referral']);
        setLocation("");
    };

    useEffect(() => {
        setShowSpinner(false);
        if (params.url ?? false) {
            setShowServiceForm(true);
        }
    }, [params.url]);

    const onShowServiceForm = (event) => {
        setShowServiceForm(true);
    };

    return (
        <>
            <div className="container-xxl bg-white p-0">
                { showSpinner && <Spinner /> }
                <Header onShowServiceForm={onShowServiceForm} />
                <About onShowServiceForm={onShowServiceForm} />
                <Service params={ params } onShowServiceForm={onShowServiceForm} />
                <ApiSpecification onShowServiceForm={onShowServiceForm} />
                <Footer onShowServiceForm={onShowServiceForm} />
                <YMInitializer accounts={[ 89564743 ]} options={{ webvisor: true }} />
            </div>
            {(showServiceForm) && (
                <Modal onClose={() => setShowServiceForm(false)}>
                    <ServiceForm name="modal" params={ params } />
                </Modal>
            )}
        </>
    )
}

export default MainPage;