
function Hero({ onShowServiceForm }) {

    return (
        <div className="container-xxl bg-primary hero-header mb-0 mb-sm-5">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 text-center">
                        <h1 className="text-white mb-4 animated fadeInDown">Это твой бесплатный сервис коротких ссылок и QR-кодов</h1>
                        <p className="text-white mx-3 animated zoomIn">
                            Иногда ссылки бывают длинными. Совсем иногда — очень длинными. Но почти всегда — слишком длинными.
                        </p>
                        <p className="text-white mx-3 text-center animated zoomIn">
                            Для того чтобы избавиться от этой проблемы, был создан наш сайт. Да, таких тысячи, но <strong>наш лучше</strong>! Потому что для людей. Убедись.
                        </p>
                        <a className="btn btn-light text-primary rounded-pill border-2 mt-3 mb-5 py-3 px-5 animated wobble" onClick={onShowServiceForm}>
                            <strong>Создать короткую ссылку и QR-код</strong>
                        </a>
                    </div>
                    <div className="col-lg-6 text-center text-lg-start">
                        <img className="img-fluid animated zoomIn d-none d-sm-inline" src="/misc/img/hero.png" alt="" />
                        <img className="img-fluid animated zoomIn d-inline d-sm-none" src="/misc/img/newsletter.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero;