
import { Cookies } from "react-cookie";

// const KEY_ACCOUNT = 'mrfx_account';
const KEY_REFERRAL = 'mrfx_referral';
// const KEY_REFERRER = 'mrfx_referer';

class StorageService {

    get(key) {
        return localStorage.getItem(key);
    }

    set(key, value) {
        localStorage.setItem(key, value);
    }

    remove(key) {
        localStorage.removeItem(key);
    }

    getReferral() {
        return this.get(KEY_REFERRAL);
    }

    setReferral(referral) {
        console.log("referral set", referral);
        this.set(KEY_REFERRAL, referral);
        const cookies = new Cookies();
        cookies.set(KEY_REFERRAL, referral, { path: "/", expires: null });
    }

    refreshReferral() {
        const cookies = new Cookies();
        const referral = this.getReferral() ?? cookies.get(KEY_REFERRAL);
        console.log("referral refresh", referral);
        if ( referral ) {
            this.setReferral(referral);
        };
    }

    /*
    getUser() {
        const data = this.get(KEY_REFERRAL);
        return (data) ? JSON.parse(data) : null;
    }

    setUser(user: TUserAccount) {
        this.set(KEY_REFERRAL, JSON.stringify(user));
    }

    removeUser() {
        this.remove(STORAGE_USER_KEY);
    }

    getLocalAccessToken() {
        return this.getUser()?.accessToken;
    }

    getLocalRefreshToken() {
        return this.getUser()?.refreshToken;
    }

    setLocalAccessToken(token: string) {
        let user = this.getUser();
        user.accessToken = token;
        this.setUser(user);
    }
    */

}
  
export default new StorageService();