import React, { useEffect } from "react";

import Bookmarklet from '../links/bookmarklet';
import TelegramLink from '../links/telegram';

const ServiceResult = ({ url, onClose }) => {

    const copyToClipboardURL = () => {
        navigator?.clipboard?.writeText(url);
    };
    useEffect(() => copyToClipboardURL());

    return (
        <>
            <h3 className="text-white d-none d-sm-inline">Короткая ссылка и QR-код</h3>
            <h3 className="text-white d-inline d-sm-none">Ссылка и QR-код</h3>
            <p className="text-white mt-1">Благодарим за доверие нашему сервису. Вот созданные ссылка и QR-код:</p>
            <div>
                <div className="position-relative w-100">
                    <input className="form-control rounded-pill ps-4 pe-5 pt-2 pb-2" type="url" name="url" defaultValue={ url } placeholder="Адрес страницы" readOnly="readonly" />
                    <button type="submit" className="btn btn-primary rounded-pill position-absolute top-0 end-0 py-1 m-1 d-none d-sm-block" >
                        <i className="fa fa-copy ms-1" title="Скопировать ссылку" data-toggle="tooltip" onClick={copyToClipboardURL}></i>
                    </button>
                    <small className="ms-3 text-black-50 wow fadeInUp" data-wow-delay="1s">
                        Ссылка скопирована в буфер
                    </small>
                </div>
            </div>

            <div className="row text-white">
                <div className="col-12 col-sm-6 col-md-4 col-xxl-3 mb-3 text-center">
                    <img className="w-75" src={ 'https://mrfx.ru/--qr?url=' + encodeURIComponent(url) } alt="QR-код короткой ссылки от MrFix" />
                </div>
                <div className="col-12 col-sm-6 col-md-8 col-xxl-9">
                    <p><small>
                        Сохрани полученную ссылку <a className="bg-secondary text-white" href={url} target="_blank" rel="noreferrer">{url}</a> или перешли QR-код.
                    </small></p>
                    <p><small>
                        Используй <span className="text-white d-none d-sm-inline">наш telegram-</span>бот <TelegramLink className="bg-secondary text-white fw-bold">@MrFxUrlBot</TelegramLink> для удобства работы. Там тоже приходит QR-код.
                    </small></p>
                    <p className="d-none d-sm-block"><small>
                        Сохрани эту закладку <Bookmarklet className="bg-secondary text-white" /> в браузере (<u>перетащи на панель браузера</u>) для быстрого создания ссылки. Нажми на закладку, когда нужно укоротить адрес открытого сайта.
                    </small></p>
                </div>
                <div className="col-12 text-end">
                    <button type="button" className="btn bg-light fw-bold text-primary rounded-pill me-2" onClick={copyToClipboardURL}>
                        Скопировать <span className="d-none d-xl-inline"> ссылку</span> <i className="fa fa-copy ms-1" title="Скопировать короткую ссылку" data-toggle="tooltip"></i>
                    </button>
                    <a href={url} target="_blank" rel="noreferrer" className="btn btn-light text-primary rounded-pill me-2">
                        Проверить <i className="fa fa-external-link ms-1"></i>
                    </a>
                    <button type="button" className="btn btn-dark text-white rounded-pill d-none d-sm-inline" onClick={onClose}>
                        Закрыть <i className="fa fa-close ms-1"></i>
                    </button>
                </div>
            </div>
        </>
    );
}

export default ServiceResult;