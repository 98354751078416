const TelegramLink = ({ children, className }) => {
    const text = children ?? "@MrFxUrlBot";
    const link = `<a href="https://t.me/MrFxUrlBot" target="_blank" class="${className ?? ''}">${text}</a>`;
    return (
        <span dangerouslySetInnerHTML={{__html: link}}></span>
    )
}

export default TelegramLink;

