import Bookmarklet from '../links/bookmarklet';
import TelegramLink from '../links/telegram';

function About({ onShowServiceForm }) {
    return (
        <div className="container-xxl">
            <a id="about"></a>
            <div className="container">
                <div className="row g-5 align-items-center">
                    <div className="col-lg-5 wow zoomIn d-none d-lg-block" data-wow-delay="0.1s">
                        <img className="img-fluid" src="/misc/img/critical-thinking.jpg" alt="О нашем сервисе" />
                    </div>
                    <div className="col-12 col-lg-7 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="d-inline-block border rounded-pill text-primary px-4 mb-3 mt-0 mt-sm-5">О нашем сервисе</div>
                        <h2 className="mb-4 text-primary">Зачем нужны короткие ссылки?</h2>
                        <p className="mb-4">
                            Довольно часто при общении в интернете люди пересылают друг другу ссылки. Иногда эти ссылки бывают длинными. Совсем иногда — очень длинными. Но почти всегда — слишком длинными. Для того чтобы избавиться от этой проблемы, был создан этот сайт. Да, я знаю, что таких тысячи, но этот же лучше!
                        </p>
                        <p className="mb-4">
                            А дальше все просто: вставляете ссылку в поле для ввода, нажимаете "Клик" и получаете короткий, совсем короткий URL.
                        </p>
                        <p className="d-none d-sm-inline">Для быстрого создания ссылок сохраните наш букмарклет
                            <Bookmarklet className="badge bg-success text-white" />
                            (перетащите на панель закладок браузера). Нажмите на закладку, когда хотите укоротить адрес открытого просматриваемого сайта.
                        </p>
                        <p className="mb-4">
                            Иногда (нередко) данным сервисом пользуются злоумышленники: хакеры, крекеры, спамы, куки, закладки, троянские кони… Очевидно, что это противоречит всем <a href="#rules" className="badge bg-success text-white">правилам</a> использования сервиса, а также здравому смыслу. Если вы столкнулись с такой cитуацией, пожалуйста, <a href="#contacts" className="badge bg-success text-white">напишите</a> нашим специалистам.
                        </p>
                        <div className="row g-3 mb-4">
                            <div className="col-12 d-flex">
                                <div className="flex-shrink-0 btn-lg-square">
                                    <i className="fa fa-area-chart fa-2x text-success"></i>
                                </div>
                                <div className="ms-4">
                                    <h6>Стастика переходов</h6>
                                    <span>Мы собираем для вас стастистику переходов по ссылкам</span>
                                </div>
                            </div>
                            <div className="col-12 d-flex">
                                <div className="flex-shrink-0 btn-lg-square">
                                    <i className="fa fa-briefcase fa-2x text-success"></i>
                                </div>
                                <div className="ms-4">
                                    <h6>Бизнес-аккаунты</h6>
                                    <span>Предлагаем <a href="#prices">расширенные возможности</a> для ведения бизнеса</span>
                                </div>
                            </div>
                            <div className="col-12 d-flex">
                                <div className="flex-shrink-0 btn-lg-square">
                                    <i className="fa fa-telegram fa-2x text-success"></i>
                                </div>
                                <div className="ms-4">
                                    <h6>Бот Telegram</h6>
                                    <span>Используйте <TelegramLink>нашего telegram-бота</TelegramLink> для удобства работы</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;