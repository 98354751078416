import React, { useState, useEffect } from "react";

import Bookmarklet from '../links/bookmarklet';
import TelegramLink from '../links/telegram';

import ServiceResult from './service-result';
import Storage from "../service/storage-service";

import ym from 'react-yandex-metrika';

const ServiceForm = ({ name, params }) => {

    const getUrlParams = ( params ) => {
        return {
            url: params.url ?? "",
            email: params.email ?? "",
            passsword: params.passsword ?? "",
            status: params.status ?? "301",
            statistic: (params.statistic ?? false) ? 1 : 0,
            referral: params.referral ?? Storage.getReferral(),
        };
    };
    
    const [ query, setQuery ] = useState(getUrlParams(params));
    const [ resultURL, setResultURL ] = useState();

    const canCreateUrl = query.url !== "";

    const handleChangeValue = (event) => {
        const target = event.target;
        // console.info("handleChangeValue", target);
        const name = target.name;
        if ( name ) {
            const value = target.type === 'checkbox' ? ((target.checked ? 1 : 0)) : target.value;
            const newQuery = { ...query, [name]: value };
            setQuery(newQuery);
            // console.info("handleChangeValue", newQuery);
        };
    };

    // ex: http://localhost:3000/?url=http%3A%2F%2Fmrfx.ru%2F%3Furl%3Dhttp%3A%2F%2Fmrfx.ru%26utm_source%3D01%26utm_medium%3D02&password=SOME_PASSWORD&status=302&statistic=1
    const getUrlUTMs = (url) => {
        let UTMs = {};
        if ( url ) {
            let values = new URLSearchParams(new URL(url).search);
            UTMs["utm_source"] = values.get("utm_source");
            UTMs["utm_medium"] = values.get("utm_medium");
            UTMs["utm_campaign"] = values.get("utm_campaign");
            UTMs["utm_content"] = values.get("utm_content");
            UTMs["utm_term"] = values.get("utm_term");
        };
        return UTMs;
    };
    const [ utms, setUTMs ] = useState(getUrlUTMs(params?.url));
    const handleChangeUTM = (event) => {
        const target = event.target;
        const name = target.name;
        if ( name ) {
            const value = target.value;
            const newUTMs = { ...utms, [name]: value };
            setUTMs(newUTMs);

            let url = query.url ?? undefined;
            if ( url ) {
                let obj = new URL(url);
                let values = new URLSearchParams(obj.search);
                values.set(name, value);
                obj.search = values.toString();
                url = obj.toString();
                const newQuery = { ...query, url };
                setQuery(newQuery);
            };
        };
    };

    const [ showBlockUTMs, setShowBlockUTMs ] = useState(false);
    const [ showBlockUrlAccess, setShowBlockUrlAccess ] = useState(false);
    const [ showBlockSettings, setShowBlockSettings ] = useState(false);
    const [ showAll, setShowAll ] = useState(false);
    useEffect(() => {
        setShowBlockUTMs(showAll);
        setShowBlockUrlAccess(showAll);
        setShowBlockSettings(showAll);
    }, [ showAll ]);
    
    useEffect(() => {
        if ( utms.utm_source || utms.utm_medium || utms.utm_campaign || utms.utm_content || utms.utm_term ) {
            setShowBlockUTMs(true);
        };
        if ( params.email || params.password ) {
            setShowBlockUrlAccess(true);
        };
        if ( params.status || params.statistic ) {
            setShowBlockSettings(true);
        };

        ym("reachGoal", "OPEN_FORM");

        Storage.refreshReferral();

    }, []);

    // const reachGoalTest = () => `ym(89564743, "reachGoal", "CREATE_URL");console.info(89564743, "reachGoal", "CREATE_URL");`;
    // const reachGoalTest2 = () => `console.info(89564743, "reachGoal", "CREATE_URL");`;

    const handleSubmitForm = (event) => {
        event.preventDefault();
        const searchParams = new URLSearchParams();
        Object.keys(query).forEach((key) => (query[key]) ? searchParams.append(key, query[key]) : null);
        const urlParams = searchParams.toString();
        // console.info("getUrl", urlParams);
        fetch("https://mrfx.ru/--?" + urlParams)
            //.then((response) => (response.ok) ? response.json() : null)
            .then((response) => response.text())
            .then((response) => {
                setShowAll(false);
                setShowBlockUTMs(false);
                setShowBlockUrlAccess(false);
                setShowBlockSettings(false);
                setResultURL(response);
            })
            .catch((error) => {
                console.error(error);
            });

        ym("reachGoal", "CREATE_URL");

    };

    return (resultURL) ?
    (

        <ServiceResult url={ resultURL } onClose={() => setResultURL(null)} />

    ) : (
        <form onSubmit={ handleSubmitForm }>

            <h3 className="text-white">Создать <span className="d-none d-sm-inline">короткую </span>ссылку и QR<span className="d-none d-sm-inline">-код</span></h3>
            <small className="text-white d-none d-sm-inline">Перетащи ссылку в поле или введи URL-адрес страницы, <u className="fw-bold cursor-pointer" onClick={() => setShowAll(!showAll)}>настрой параметры ссылки</u></small>
            <small className="text-white d-sm-inline d-sm-none">Укажи адрес страницы и <u className="fw-bold cursor-pointer" onClick={() => setShowAll(!showAll)}>настройки ссылки</u></small>
            
            <div className="w-100 mt-3 d-block d-sm-flex raw">
                <div className="position-relative w-100">
                    <input className="form-control rounded-pill ps-4 pe-5 pt-2 pb-2" type="url" name="url" value={ query.url } onChange={handleChangeValue} placeholder="Адрес страницы"  />
                    <button type="submit" className={ (canCreateUrl ? "btn-primary" : "btn-secondary") + " btn rounded-pill position-absolute top-0 end-0 py-1 m-1 d-none d-sm-block"} disabled={ !canCreateUrl }>
                        Создать <i className="fa fa-anchor ms-1" title="Создать короткую ссылку" data-toggle="tooltip"></i>
                    </button>
                </div>
                <div className="btn-toolbar justify-content-between">
                    <div className="text-nowrap mt-1 ms-2">
                        <button type="button" className={((showBlockUTMs) ? "text-white" : "text-success") + " btn shadow-none px-1"} onClick={() => setShowBlockUTMs(!showBlockUTMs)}>
                            <i className="fa fa-tags fs-4" title="Редактировать UTM-метки" data-toggle="tooltip"></i>
                        </button>
                        <button type="button" className={((showBlockUrlAccess) ? "text-white" : "text-success") + " btn shadow-none px-1"} onClick={() => setShowBlockUrlAccess(!showBlockUrlAccess)}>
                            <i className="fa fa-lock fs-4" title="Настроить доступ" data-toggle="tooltip"></i>
                        </button>
                        <button type="button" className={((showBlockSettings) ? "text-white" : "text-success") + " btn shadow-none px-1"} onClick={() => setShowBlockSettings(!showBlockSettings)}>
                            <i className="fa fa-cog fs-4" title="Дополнительные опции" data-toggle="tooltip"></i>
                        </button>
                    </div>
                    <button type="submit" className={ (canCreateUrl ? "btn-dark" : "btn-secondary") + " btn text-white rounded-pill py-1 my-1 d-block d-sm-none"} disabled={ !canCreateUrl }>
                        Создать <i className="fa fa-anchor ms-1" title="Создать короткую ссылку" data-toggle="tooltip"></i>
                    </button>
                </div>
            </div>

            <div className={"mt-3 input-group-sm text-white" + ( showBlockUTMs ? "" : " d-none" )}>
                <div className="row">
                    <h5 className="col-12 text-white">
                        UTM-метки
                        <button type="button" className="btn text-body ps-2 pt-0" onClick={() => setShowBlockUTMs(false)}><i className="fa fa-times"></i></button>
                    </h5>
                    <div className="col-6 col-md-6 col-lg-4 mb-1">
                        <label className="small fw-bold d-none d-sm-inline" htmlFor={name + "_utm_source"}>utm_source (источник перехода])</label>
                        <input className="form-control form-control-sm" type="text" name="utm_source" id={name + "_utm_source"} defaultValue={ utms.utm_source ?? "" } onChange={handleChangeUTM} title="Источник кампании" data-toggle="tooltip" placeholder="utm_source: vk, ok, yandex" />
                    </div>
                    <div className="col-6 col-md-6 col-lg-4 mb-1">
                        <label className="small fw-bold d-none d-sm-inline" htmlFor={name + "_utm_medium"}>utm_medium (тип трафика)</label>
                        <input className="form-control form-control-sm" type="text" name="utm_medium" id={name + "_utm_medium"} defaultValue={ utms.utm_medium ?? "" } onChange={handleChangeUTM} title="Тип трафика" data-toggle="tooltip" placeholder="utm_medium: cpc, email, banner, article" />
                    </div>
                    <div className="col-6 col-md-6 col-lg-4 mb-1">
                        <label className="small fw-bold d-none d-sm-inline" htmlFor={name + "_utm_campaign"}>utm_campaign (рекламная кампания)</label>
                        <input className="form-control form-control-sm" type="text" name="utm_campaign" id={name + "_utm_campaign"} defaultValue={ utms.utm_campaign ?? "" } onChange={handleChangeUTM} title="Название кампании" data-toggle="tooltip" placeholder="utm_campaign: promo, discount, sale" />
                    </div>
                    <div className="col-6 col-md-6 col-lg-4 mb-1">
                        <label className="small fw-bold d-none d-sm-inline" htmlFor={name + "_utm_content"}>utm_content (содержание)</label>
                        <input className="form-control form-control-sm" type="text" name="utm_content" id={name + "_utm_content"} defaultValue={ utms.utm_content ?? "" } onChange={handleChangeUTM} title="Идентификатор объявления" data-toggle="tooltip" placeholder="utm_content: link, landing page" />
                    </div>
                    <div className="col-6 col-md-6 col-lg-4">
                        <label className="small fw-bold d-none d-sm-inline" htmlFor={name + "_utm_term"}>utm_term (ключевая фраза)</label>
                        <input className="form-control form-control-sm" type="text" name="utm_term" id={name + "_utm_term"} defaultValue={ utms.utm_term ?? "" } onChange={handleChangeUTM} title="Ключевое слово" data-toggle="tooltip" placeholder="utm_term: free, -30%, registration" />
                    </div>
                </div>
            </div>

            <div className={"mt-3 input-group-sm text-white" + ( showBlockUrlAccess ? "" : " d-none" )}>
                <div className="row">
                    <h5 className="col-12 text-white">
                        Настройки доступа
                        <span type="button" className="btn text-body ps-2 pt-0" onClick={() => setShowBlockUrlAccess(false)}><i className="fa fa-times"></i></span>
                    </h5>
                    <label className="small d-none d-sm-inline">Доступ к настройкам и статистике переходов</label>
                    <div className="col-6 col-sm-6 col-lg-8 mb-1">
                        <label className="small  fw-bold d-none d-sm-inline" htmlFor={name + "_email"}>Электронная почта</label>
                        <input className="form-control form-control-sm" type="text" name="email" id={name + "_email"} defaultValue={ params.email ?? "" } onChange={handleChangeValue} title="Электронная почта для восстановления доступа и получения статистики" data-toggle="tooltip" placeholder="Твой e-mail" />
                    </div>
                    <div className="col-6 col-sm-6 col-lg-4 mb-1">
                        <label className="small fw-bold d-none d-sm-inline" htmlFor={name + "_password"}>Пароль доступа</label>
                        <input className="form-control form-control-sm" type="text" name="password" id={name + "_password"} defaultValue={ params.password ?? "" } onChange={handleChangeValue} title="Персональный пароль доступа к настройкам и статистике" data-toggle="tooltip" placeholder="Пароль" />
                    </div>
                </div>
            </div>

            <div className={"mt-3 input-group-sm text-white" + ( showBlockSettings ? "" : " d-none" )}>
                <div className="row">
                    <h5 className="col-12 text-white">
                        Дополнительные опции
                        <button type="button" className="btn text-body ps-2 pt-0" onClick={() => setShowBlockSettings(false)}><i className="fa fa-times"></i></button>
                    </h5>
                    <div className="col-6 col-sm-6 col-lg-4">
                        <label className="small fw-bold">Код ответа HTTP</label><br/>
                        <div className="form-check-inline">
                            <input className="form-check-input" type="radio" name="status" value="301" id={name + "_status_301"} checked={ query.status === "301" } onChange={handleChangeValue} />
                            <label className="form-check-label small ps-1" htmlFor={name + "_status_301"} title="Перемещено навсегда" data-toggle="tooltip">301</label>
                            <input className="form-check-input ms-3" type="radio" name="status" value="302" id={name + "_status_302"}checked={ query.status === "302" } onChange={handleChangeValue} />
                            <label className="form-check-label small ps-1" htmlFor={name + "_status_302"} title="Перемещено временно" data-toggle="tooltip">302</label>
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-lg-4">
                        <label className="small fw-bold">Cтатистика</label><br/>
                        <div className="form-switch">
                            <input className="form-check-input" type="checkbox" name="statistic" id={name + "_statistic"} checked={ query.statistic === 1 } onChange={handleChangeValue} />
                            <label className="small ps-1 text-nowrap" htmlFor={name + "_statistic"} title="Собирать статистику переходов по ссылке" data-toggle="tooltip">Собирать<span className="d-none d-sm-inline"> данные</span></label>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="mt-2 text-white d-none d-sm-block">
                <div className="row">
                    <small className="col-12 text-white px-3">
                        Сохрани эту закладку <Bookmarklet className="bg-secondary text-white" /> в браузере (<u>перетащи на панель браузера</u>) для быстрого создания ссылки. Нажми на закладку, когда нужно укоротить адрес открытого сайта. Или воспользуйся нашим telegram-ботом <TelegramLink className="bg-secondary text-white fw-bold">@MrFxUrlBot</TelegramLink>.
                    </small>
                </div>
            </div>

            <input type="hidden" name="referral" defaultValue={ params.referral } />

        </form>
    )
}

export default ServiceForm;