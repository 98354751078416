const VKontakteLink = ({ children, className }) => {
    const text = children ?? "#MrFxRu";
    const link = `<a href="https://vk.com/MrFxRu" target="_blank" class="${className ?? ''}">${text}</a>`;
    return (
        <span dangerouslySetInnerHTML={{__html: link}}></span>
    )
}

export default VKontakteLink;

