function Navbar({ onShowServiceForm }) {
    return (
        <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
            <a href="/" className="navbar-brand p-0">
                <h1 className="m-0">MrFix <i className="fa fa-external-link ms-1"></i></h1>
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span className="fa fa-bars"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav ms-auto py-0">
                    <a href="#about" className="nav-item nav-link">О сервисе</a>
                    <a href="#api" className="nav-item nav-link">API</a>
                    {/*<a href="#prices" className="nav-item nav-link">Тарифы</a>*/}
                    <a href="#contacts" className="nav-item nav-link">Контакты</a>
                </div>
                <a href="#service" className="fw-bold btn btn-light rounded-pill text-primary py-2 px-4 ms-lg-5" onClick={onShowServiceForm}>Создать ссылку</a>
            </div>
        </nav>
    )
}

export default Navbar;