
function ApiSpecification() {
    return (
        <div className="container-xxl my-5">
            <a id="api"></a>
            <div className="container">
                <div className="row g-5 align-items-center">
                    <div className="col-12 col-lg-7 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="d-inline-block border rounded-pill text-primary px-4 mb-3 mt-0 mt-sm-5">Описание API</div>
                        <h2 className="mb-4 text-primary">API для разработчиков</h2>
                        <p className="mb-4">
                            У нашего сервиса есть API, позволяющий автоматизировать работу разработчиков.<br />
                        </p>
                        <h5 className="text-primary">Чтобы создать короткую ссылку</h5>
                        <p>достаточно отправить запрос на адрес <span className="badge bg-success text-white">https://mrfx.ru/--</span> с параматром url:</p>
                        <p className="mb-4">
                            Так:<br/>
                            <code>
                                curl https://mrfx.ru/--?url=<strong className="text-dark">https://gitcity.ru</strong>
                            </code>
                        </p>
                        <p className="mb-4">
                            Или так:<br/>
                            <code>
                                &lt;?= file_get_contents("https://mrfx.ru/--?url=<strong className="text-dark">https://gitcity.ru</strong>"); ?&gt;
                            </code>
                        </p>

                        <h5 className="text-primary">Чтобы создать QR-код</h5>
                        <p>отправь запрос на адрес <span className="badge bg-success text-white">https://mrfx.ru/--qr</span> с параматром url:</p>
                        <p className="mb-4">
                            Так:<br/>
                            <code>
                                curl https://mrfx.ru/--qr?url=<strong className="text-dark">https://gitcity.ru</strong>
                            </code>
                        </p>
                        <p className="mb-4">
                            Или просто укажи необходимый адрес в источнике рисунка:<br/>
                            <code>
                                &lt;img src="https://mrfx.ru/--qr?url=<strong className="text-dark">https://gitcity.ru</strong>" /&gt;
                            </code>
                        </p>
                    </div>
                    <div className="col-lg-5 wow zoomIn d-none d-lg-block" data-wow-delay="0.1s">
                        <img className="img-fluid" src="/misc/img/voice-chat.jpg" alt="Описание API" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApiSpecification;