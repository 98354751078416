const EmailLink = ({ children, className }) => {
    const text = children ?? "info@mrfx.ru";
    const link = `<a href="mailto:info@mrfx.ru" class="${className ?? ''}">${text}</a>`;
    return (
        <span dangerouslySetInnerHTML={{__html: link}}></span>
    )
}

export default EmailLink;

