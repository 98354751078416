import React, { useEffect } from "react";
import styles from "./modal.module.css";

function Modal({ title, onClose, children }) {

    useEffect(() => {
        const closeOnEscape = (evt) => {
            if (evt.key === 'Escape') {
                onClose();
            };
        };
        window.addEventListener('keydown', closeOnEscape);
        return () => {
            window.removeEventListener('keydown', closeOnEscape);
        }
    }, [onClose]);

    return (
        <div className={ styles.modal }>
            <div className={ styles.overlay } onClick={onClose} />
            <div className={ styles.content + " p-4" }>
                <div className={ styles.body + " p-4 bg-primary text-white" }>
                    <button type="button" className={ styles.close }  onClick={onClose}><i className="text-white fa fa-2x fa-times"></i></button>
                    { children }
                </div>
            </div>
        </div>
    )
}

export default Modal;