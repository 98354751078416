import ServiceForm from './service-form';

function Service({ params }) {
    return (
        <>
            <a id="service"></a>
            <div className="container-xxl bg-primary mt-0 mt-sm-4 mb-0 py-sm-4 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-8  my-4">
                            <ServiceForm name="static" params={ params } />
                        </div>
                        <div className="col-md-4 text-center mb-n5 d-none d-md-block">
                            <img className="img-fluid mt-5" style={{ maxHeight: "250px" }} src="/misc/img/newsletter.png" alt="Создать короткую ссылку" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Service;